<template>
    <div class="stage-summary">
        <app-loader v-if="loading" />

        <app-error v-model="error.show" :message="error.message" />

        <dialog-broadband-add-success
            v-if="dialogs.success.show"
            v-model="dialogs.success.show"

            @closes="onSuccessCloses"
        />

        <div class="summary">
            <div class="line">
                <div class="key">Customer:</div>
                <div class="value">
                    {{ first_name }} {{ last_name }}
                    <span v-if="email"> ({{ email }})</span>
                    <span v-if="mobile_number">, Mobile: {{ mobile_number }}</span>
                    <span v-if="ext_ref1"> (Ext Ref1: {{ ext_ref1 }})</span>
                </div>
            </div>

            <div class="line">
                <div class="key">Plan:</div>
                <div class="value">{{ plan }}</div>
            </div>

            <div class="line">
                <div class="key">MSISDN:</div>
                <div class="value">{{ msisdn }}</div>
            </div>

            <div class="line">
                <div class="key">SIM ID:</div>
                <div class="value">{{ imsi }}</div>
            </div>

            <!-- <div class="line">
                <div class="key">Voicemail:</div>
                <div class="value">{{ voicemail }}</div>
            </div> -->
        </div>

        <button class="btn btn-primary btn-small" @click="confirm">Confirm</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import dialogBroadbandAddSuccess from '../dialogs/dialog-broadband-add-success'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        mobile: { type: Object, required: true },
    },

    components: {
        appLoader,
        appError,

        dialogBroadbandAddSuccess,
    },

    data() {
        return {
            loading: false,

            error: {
                show: false,
                message: '',
            },

            dialogs: {
                success: {
                    show: false,
                }
            },

            MSISDN_providers: [
                { "VoiceSPID": "1", "Name": "Vodafone" },
                { "VoiceSPID": "2", "Name": "Spark" },
                { "VoiceSPID": "3", "Name": "Call Plus" },
                { "VoiceSPID": "4", "Name": "Orcon" },
                { "VoiceSPID": "5", "Name": "Actrix" },
                { "VoiceSPID": "6", "Name": "Airnet" },
                { "VoiceSPID": "7", "Name": "BayCity New Zealand" },
                { "VoiceSPID": "8", "Name": "Business Online Limited" },
                { "VoiceSPID": "9", "Name": "Cable &amp; Wireless" },
                { "VoiceSPID": "10", "Name": "Call South" },
                { "VoiceSPID": "11", "Name": "Cogent Communications Ltd" },
                { "VoiceSPID": "12", "Name": "Communitel Ltd" },
                { "VoiceSPID": "13", "Name": "Compass" },
                { "VoiceSPID": "14", "Name": "Deltacom" },
                { "VoiceSPID": "15", "Name": "DigiPlus" },
                { "VoiceSPID": "16", "Name": "Digital Island" },
                { "VoiceSPID": "17", "Name": "Ecosse Business Equipment Ltd" },
                { "VoiceSPID": "18", "Name": "Esurf Wireless" },
                { "VoiceSPID": "19", "Name": "Eziphone Limied" },
                { "VoiceSPID": "20", "Name": "Flip" },
                { "VoiceSPID": "21", "Name": "Fuel-Tel" },
                { "VoiceSPID": "22", "Name": "GoTalk Limited" },
                { "VoiceSPID": "23", "Name": "ICONZ" },
                { "VoiceSPID": "24", "Name": "Image Net" },
                { "VoiceSPID": "25", "Name": "InspireNet" },
                { "VoiceSPID": "26", "Name": "Internet Solutions Ltd" },
                { "VoiceSPID": "27", "Name": "Irongate New Zealand Ltd" },
                { "VoiceSPID": "28", "Name": "Kiwi Reconnect" },
                { "VoiceSPID": "29", "Name": "Kordia Limited" },
                { "VoiceSPID": "30", "Name": "M2 NZ Ltd" },
                { "VoiceSPID": "31", "Name": "Maxnet" },
                { "VoiceSPID": "32", "Name": "Megatel" },
                { "VoiceSPID": "33", "Name": "Mercury" },
                { "VoiceSPID": "34", "Name": "MyRepublic" },
                { "VoiceSPID": "35", "Name": "OneCard" },
                { "VoiceSPID": "36", "Name": "OneNetwork" },
                { "VoiceSPID": "37", "Name": "OneZone" },
                { "VoiceSPID": "38", "Name": "Planet Communications" },
                { "VoiceSPID": "39", "Name": "Quicker Net" },
                { "VoiceSPID": "40", "Name": "Reconx" },
                { "VoiceSPID": "41", "Name": "Securecom Hostings Ltd" },
                { "VoiceSPID": "42", "Name": "Simply Calling Ltd" },
                { "VoiceSPID": "43", "Name": "Smartel" },
                { "VoiceSPID": "44", "Name": "Snap" },
                { "VoiceSPID": "45", "Name": "Sprint" },
                { "VoiceSPID": "46", "Name": "State Services Commission" },
                { "VoiceSPID": "47", "Name": "Tasman Solutions" },
                { "VoiceSPID": "48", "Name": "TeamTalk Ltd" },
                { "VoiceSPID": "49", "Name": "telcoinabox" },
                { "VoiceSPID": "50", "Name": "TelstraClear Residential" },
                { "VoiceSPID": "51", "Name": "The Packing Shed" },
                { "VoiceSPID": "52", "Name": "TrustPower Limited" },
                { "VoiceSPID": "53", "Name": "UCS Limited" },
                { "VoiceSPID": "54", "Name": "Woosh" },
                { "VoiceSPID": "55", "Name": "World Exchange" },
                { "VoiceSPID": "56", "Name": "WorldNet" },
                { "VoiceSPID": "57", "Name": "Xtreme Networks" },
                { "VoiceSPID": "58", "Name": "Zintel" },
                { "VoiceSPID": "59", "Name": "5Star Net" }
            ],

            MSISDN_avaliable_phones: [
                { "Number": "02123456700", "Title": "(021) 1234-56-700" },
                { "Number": "02123456701", "Title": "(021) 1234-56-701" },
                { "Number": "02123456702", "Title": "(021) 1234-56-702" },
                { "Number": "02123456703", "Title": "(021) 1234-56-703" },
                { "Number": "02123456704", "Title": "(021) 1234-56-704" },
                { "Number": "02123456705", "Title": "(021) 1234-56-705" },
                { "Number": "02123456706", "Title": "(021) 1234-56-706" },
                { "Number": "02123456707", "Title": "(021) 1234-56-707" },
                { "Number": "02123456708", "Title": "(021) 1234-56-708" },
                { "Number": "02123456709", "Title": "(021) 1234-56-709" },
                { "Number": "02123456710", "Title": "(021) 1234-56-710" },
                { "Number": "02123456711", "Title": "(021) 1234-56-711" },
                { "Number": "02123456712", "Title": "(021) 1234-56-712" },
                { "Number": "02123456713", "Title": "(021) 1234-56-713" },
                { "Number": "02123456714", "Title": "(021) 1234-56-714" },
            ],
        }
    },
    
    methods: {
        init() {},

        confirm() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                CustomerUUID: this.mobile.customer.UUID,
                // ReferenceCode: ,
                ProductAvailabilityUUID: this.mobile.service.plan,

                AutoAllocateMSISDN: false,
                MSISDN: this.mobile.service.MSISDN.allocate == 'number' ? this.mobile.service.MSISDN.phone : this.mobile.service.MSISDN.manual,

                AutoAllocateIMSI: false,
                // IMSI: ,

                Voicemail: this.mobile.service.voicemail,

                // NewGroup: ,
                // ExistingGroupUUID: ,

                SIMID: this.mobile.service.IMSI.sim_id,
            }

            this.$store.dispatch('api_postpay/CreatePostpayProduct', params)
                .then(() => {
                    this.error.show = false
                    this.error.message = ''

                    this.loading = false

                    this.dialogs.success.show = true
                })
                .catch(error => {
                    this.error.show = true
                    this.error.message = errMessage(error)

                    this.loading = false
                })
        },

        onSuccessCloses() {
            this.$router.push({
                name: 'customer-products',
                params: {
                    uuid: this.mobile.customer.UUID,
                    type: 'postpay-mobile',
                }
            })
        },
    },

    computed: {
        ...mapGetters([
            'modules_env',

            'current_spid',
            'mobile_services_plans',
        ]),

        customer_uuid() {
            return this.mobile.customer.UUID
        },

        first_name() {
            return this.mobile.customer.FirstName
        },

        last_name() {
            return this.mobile.customer.LastName
        },

        email() {
            return this.mobile.customer.Email
        },

        mobile_number() {
            return this.mobile.customer.MobileNumber
        },

        ext_ref1() {
            return this.mobile.customer.ExternalRef1
        },

        plan() {
            return this.mobile.service.plan && this.mobile_services_plans && this.mobile_services_plans[ this.mobile.service.plan ]
                ? this.mobile_services_plans[ this.mobile.service.plan ].Name + ' / ' + this.mobile_services_plans[ this.mobile.service.plan ].Description
                : '-'
        },

        imsi() {
            let imsi = 'auto'

            switch (this.mobile.service.IMSI.allocate) {
                case 'existing': {
                    imsi = this.mobile.service.IMSI.sim_id
                } break

                case 'ship': {
                    imsi = this.mobile.service.IMSI.location.address
                } break
            }

            return imsi
        },

        msisdn() {
            let msisdn = 'auto'

            switch (this.mobile.service.MSISDN.allocate) {
                case 'manual': {
                    msisdn = this.mobile.service.MSISDN.manual
                } break

                case 'existing': {
                    const provider = this.MSISDN_providers.find(provider => provider.VoiceSPID == this.mobile.service.MSISDN.provider)

                    msisdn = provider
                        ? `${ this.service.MSISDN.manual } / ${ provider.Name }`
                        : this.service.MSISDN.manual
                } break

                case 'number': {
                    const phone = this.MSISDN_avaliable_phones.find(phone => phone.Number == this.mobile.service.MSISDN.phone)
                    
                    msisdn = phone
                        ? phone.Title
                        : '-'
                } break
            }

            return msisdn
        },

        voicemail() {
            return this.mobile.service.voicemail ? 'Yes' : 'No'
        },
    },
}
</script>

<style lang="scss">
.stage-summary {
    .app-error {
        margin-bottom: 30px;
    }

    .summary {
        margin-bottom: 24px;

        .line {
            display: flex;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            .key {
                min-width: 130px;
                padding-right: 20px;
                font-size: 18px;
                font-weight: bold;
                line-height: 1.33;
                color: var(--color-text-primary);
            }

            .value {
                font-size: 16px;
                line-height: 1.5;
                color: var(--color-text-primary);
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .stage-summary {
        .summary {
            .line {
                flex-direction: column;
                font-size: 14px;
                margin-bottom: 8px;

                .key {
                    font-size: 16px;
                    padding: 0;
                    margin-bottom: 4px;
                }
            }
        }
    }
}
</style>